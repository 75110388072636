import { KlevuConfig } from "@klevu/core";
import type { GatsbyBrowser } from "gatsby";
import ContextWrapper from "./src/containers/ContextWrapper";
import { CoreErrorBoundary } from "./src/containers/core-error-boundary";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = (props) => {
	if (process.env.GATSBY_LANGUAGE === "ko-KR") {
		// eslint-disable-next-line @typescript-eslint/no-require-imports
		require("./src/styles/locales/ko.css");
	}
	if (process.env.GATSBY_KLEVU_API_KEY && process.env.GATSBY_KLEVU_API_URL) {
		KlevuConfig.init({
			url: `${process.env.GATSBY_KLEVU_API_URL}/cs/v2/search`,
			apiKey: process.env.GATSBY_KLEVU_API_KEY,
			enableKlaviyoConnector: true, //To enable Klaviyo integration for segmentation in Recommendations
		});
	} else {
		console.error("Klevu API key or URL is not set");
	}

	return (
		<CoreErrorBoundary>
			<ContextWrapper>{props.element}</ContextWrapper>
		</CoreErrorBoundary>
	);
};
