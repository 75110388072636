import {
	createContext,
	useContext,
	useReducer,
	type FC,
	type PropsWithChildren,
	type Reducer,
} from "react";
import type { CommercetoolsSession } from "../../utils/commercetools/auth-service";
import { appReducer, type Action as ReducerAction } from "./reducer";

import { assert } from "@sindresorhus/is";
import type { TCountryCode } from "countries-list";
import { graphql, useStaticQuery, type ScriptProps } from "gatsby";
import { isValidCountryCode } from "../../utils/country";

export type SiteConfig = {
	id: string;
	uuid: string;
	_uid: string;
	name: string;
	currency: string;
	language: string;
	store: string;
	zip_regex: string;
	payment_methods: string[];
	measurement_system: string;
	klaviyo_id: string;
	zendesk_key: string;
	embed_social_key: string;
	use_address_autofill: boolean;
	countries: [string, ...string[]];
	product_page_shipping_info: string;
	product_page_delivery_info: string;
	product_page_gift_wrap_info: string;
	hide_gift_card: boolean;
	google_pay_express: boolean;
	apple_pay_express: boolean;
};
export type State = {
	environment?: string;
	session?: CommercetoolsSession;
	siteConfig: SiteConfig;
	scripts: ScriptProps[];
	hideNoticeBar: boolean;
	cjEventId?: string;
	awinId?: string;
	toasterProduct?: {
		product: Queries.CommerceToolsProductFragment;
		variant: Queries.ProductVariantFragment;
	};
	userCountry?: TCountryCode;
	allowedCountries: [TCountryCode, ...TCountryCode[]];
	navOpen: boolean;
	searchOpen: boolean;
	drawerIndices: (number | null | undefined)[];
	activeSearchType: "product" | "page";
};

type Action = ReducerAction;
type Dispatch<Action> = (action: Action) => void;

const StateContext = createContext<State | undefined>(undefined);
export const AppDispatchContext = createContext<Dispatch<Action> | undefined>(
	undefined,
);

const initialState = {
	environment: process.env.NODE_ENV,
	scripts: [],
	hideNoticeBar: false,
	toasterProduct: undefined,
} satisfies Partial<State>;

const query = graphql`
	query AppContext {
		store: commerceToolsStore {
			countries {
				code
			}
			id
		}
		siteConfigs: allParsedSiteConfig {
			nodes {
				id
				uuid
				_uid
				name
				currency
				language
				store
				zip_regex
				payment_methods
				measurement_system
				countries
				zendesk_key
				embed_social_key
				product_page_shipping_info
				product_page_delivery_info
				product_page_gift_wrap_info
				hide_gift_card
				use_address_autofill
				klaviyo_id
				google_pay_express
				apple_pay_express
			}
		}
	}
`;

export const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const data = useStaticQuery<Queries.AppContextQuery>(query);

	const siteConfig = data.siteConfigs.nodes.find((siteConfig) => {
		return (
			siteConfig.language === process.env.GATSBY_LANGUAGE &&
			siteConfig.store === process.env.GATSBY_STORE
		);
	}) as SiteConfig | undefined;

	if (!siteConfig) {
		throw new Error(
			`no siteconfig found for store: ${process.env.GATSBY_STORE} and language: ${process.env.GATSBY_LANGUAGE}`,
		);
	}

	const allowedCountries = data.store?.countries
		.map((country) => country.code)
		.filter<TCountryCode>(isValidCountryCode);

	assert.nonEmptyArray<TCountryCode[] | undefined, TCountryCode>(
		allowedCountries,
		"Allowed countries in store is empty",
	);

	const [state, dispatch] = useReducer<Reducer<State, Action>>(appReducer, {
		...initialState,
		siteConfig,
		allowedCountries,
		navOpen: false,
		searchOpen: false,
		drawerIndices: [null, null],
		activeSearchType: "product",
	});

	return (
		<StateContext.Provider value={state}>
			<AppDispatchContext.Provider value={dispatch}>
				{children}
			</AppDispatchContext.Provider>
		</StateContext.Provider>
	);
};

export const useAppState = (): State => {
	const context = useContext(StateContext);

	if (context === undefined) {
		throw new Error(`useAppState must be used within a AppContextProvider`);
	}
	return context;
};
